<template>
  <div>
    <el-tabs v-model="editableTabsValue" type="card" closable @tab-remove="removeTab" @tab-click='tabClick'>
      <el-tab-pane v-for="item in editableTabs" :key="item.name" :label="item.title" :name="item.name">
        {{item.content}}
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  computed: {
    editableTabsValue: {
      get() {
        return this.$store.state.MenuStore.editableTabsValue;
      },
      set(val) {
        this.$store.state.MenuStore.editableTabsValue = val;
      },
    },
    editableTabs: {
      get() {
        return this.$store.state.MenuStore.editableTabs;
      },
      set(val) {
        this.$store.state.MenuStore.editableTabs = val;
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    tabClick(tab) {
      let obj = {};
      obj.title = tab.label;
      obj.name = tab.name;
      this.$store.commit("clickMenu", obj);
      this.$router.push({ name: tab.name });
    },
    removeTab(targetName) {
      if (targetName === "desktop") {
        return;
      }
      let tabs = this.editableTabs;
      let activeName = this.editableTabsValue;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }

      this.editableTabsValue = activeName;
      this.editableTabs = tabs.filter((tab) => tab.name !== targetName);
      this.$store.commit("setActiveTabs", this.editableTabsValue);
      sessionStorage.setItem("tabsList", JSON.stringify(this.editableTabs));
      this.$router.push({ name: this.editableTabsValue });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>