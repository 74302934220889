<template>

  <el-container class="home">
    <el-header>
      <div class="title">后台管理系统</div>
      <div class="userInfo">
        <div class="right">
                    <div class="item">3333</div>
                    <div class="alarm"></div>
        </div>
        
        <el-dropdown>
          <span class="el-dropdown-link" style="color:#fff">
            {{truename}}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item><i class="fa fa-user-circle-o fa-fw"></i>&nbsp; 个人中心</el-dropdown-item>
            <el-dropdown-item>
              <div @click="editPass">
                <i class="fa fa-edit fa-fw"></i>&nbsp; 修改密码
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div @click="logout"><i class="fa fa-power-off fa-fw"></i>&nbsp; 退&nbsp;&nbsp;出</div>
            </el-dropdown-item>

          </el-dropdown-menu>
        </el-dropdown>
      
      </div>
    </el-header>
    <el-container>
      <el-aside width="auto">
        <MenuBar></MenuBar>
      </el-aside>
      <el-container>
        <el-main>
          <i class=" arrow-icon" :class="[isCollapse?'el-icon-s-unfold':'el-icon-s-fold']" @click="iconClick"></i>
          <Tabs></Tabs>
          <router-view></router-view>
        </el-main>
        <el-footer>Copyright © 2020 - 2021 . XXX.All Rights Reserved</el-footer>
      </el-container>
    </el-container>
    <sys-dialog :title="editPassDialog.title" :height="editPassDialog.height" :width="editPassDialog.width" :visible="editPassDialog.visible" @onClose="onParentClose" @onConfirm="onParentConfirm">
      <div slot="content">
        <el-form :model="editPassForm" status-icon :rules="rules" ref="editPassForm" label-width="80px" class="demo-ruleForm" size="small">

          <el-form-item label="旧密码" prop="oldPass">
            <el-input type="password" v-model="editPassForm.oldPass"></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newPass">
            <el-input type="password" v-model="editPassForm.newPass" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="checkPass">
            <el-input type="password" v-model="editPassForm.checkPass" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </sys-dialog>
  </el-container>

</template>

<script>
import MenuBar from "../components/MenuBar.vue";
import Tabs from "../components/Tabs.vue";
import { mapState } from "vuex";
import { removeUserId, removeToken } from "../utils/auth";
import SysDialog from "../components/SysDialog";
import store from '../store'
export default {
  name: "Home",
  components: {
    MenuBar,
    Tabs,
    SysDialog,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        if (this.editPassForm.checkPass !== "") {
          this.$refs.editPassForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.editPassForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      rules: {
        oldPass: [
          {
            required: true,
            trigger: "blur",
            message: "请填写旧密码",
          },
        ],
        newPass: [{ required: true, validator: validatePass, trigger: "blur" }],
        checkPass: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
      },
      editPassForm: {
        oldPass: "",
        newPass: "",
        checkPass: "",
      },
      editPassDialog: {
        title: "修改密码",
        width: 400,
        heigth: 450,
        visible: false,
      },
      truename:""
    };
  },
  created(){
    this.truename=sessionStorage.getItem('truename')
  },
  computed: {
    ...mapState({
      isCollapse: (state) => state.MenuStore.isCollapse,
      
    }),
  },

  methods: {
    iconClick() {
      this.$store.commit("setOpenOrClose");
    },
    logout() {
      
      removeToken();
      removeUserId();
      store.commit('clear')
      sessionStorage.removeItem('tabsList')
      sessionStorage.removeItem('codeList')
      this.$router.push("/login");
    },
    editPass() {
      this.editPassDialog.visible = true;
    },
    onParentClose() {
      this.editPassDialog.visible = false;
    },
    onParentConfirm() {
      this.editPassDialog.visible = false;
    },
  },
};
</script>
<style lang='scss' scoped>
.home {
  height: 100%;
  .el-header {
    background-color: #167bd8;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title{
      font-size: 30px;
      font-weight: 600;
    }
    .userInfo{  
      display: flex;
    justify-content: space-between;
    align-items: center;
      .right{
        margin-right: 20px;
        margin-top: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
        .item {
                    border-radius: 10px;
                    display: inline-block;
                    border: 1px solid #fff;
                    background-color: #ef4d4d;
                    color: #fff;
                    font-size: 12px;
                    line-height: 14px;
                    border-radius: 8px;
                    padding: 0 5px;
                    // position: absolute;
                    //    top: 0;

                }
                .alarm {
                    min-width: 50px;
                    height: 50px;
                    background: url("~@/assets/alarm.gif") center no-repeat;
                    background-size: auto 100%;
                    // margin-top: 8px;
                }
      }
  }
    }
  
  .el-aside {
    border-right: 1px solid #e6e6e6;
  }
  .el-main {
    padding: 0;
    .arrow-icon {
      float: left;
      background: #eaedf1;
      border: 1px solid transparent;
      font-size: 23px;
      height: 39px;
      line-height: 39px !important;
      width: 40px;
      text-align: center;
    }
    /deep/ .el-tabs__header {
      position: static !important;
    }
  }
  .el-footer {
    // background-color: rgb(105, 185, 148);
    display: flex;
    justify-content: center;
    align-items: center;
    // color: #fff;
    border-top: 1px solid rgb(230, 230, 230);
    height: 50px !important;
  }
}
</style>
