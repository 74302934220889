<template>
  <fragment>
    <template v-for='menu in menuList'>
      <el-submenu v-if='menu.children !== undefined && menu.children.length > 0 ' :index="menu.path" :key='menu.path'>
        <template slot="title">
          <i :class="menu.icon" style="margin-right:8px"></i>
          <span slot="title">{{menu.label}}</span>
        </template>
        <menu-item :menuList='menu.children'></menu-item>
      </el-submenu>
      <el-menu-item @click="clickMenu(menu)" v-else :index="menu.path" :key='menu.path'>
        <i :class="menu.icon" style="margin-right:8px"></i>
        <span slot="title">{{menu.label}}</span>
      </el-menu-item>
    </template>
  </fragment>
</template>

<script>
import MenuItem from "./MenuItem";
export default {
  name: "MenuItem",
  props: ["menuList"],
  components: {
    MenuItem,
  },
  created() {
    
  },
  methods: {
    clickMenu(item) {
      this.$store.commit("clickMenu", item); 
      this.$router.push({ name: item.name });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>