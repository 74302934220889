<template>
  <el-menu :default-active="$route.path" router unique-opened class="el-menu-vertical-demo" :collapse="isCollapse">
    <menu-item :menuList='menuList'></menu-item>

  </el-menu>
</template>

<script>
import MenuItem from "../components/MenuItem";
import { mapState } from "vuex";
export default {
  components: {
    MenuItem,
  },
  computed: {
    ...mapState({
      isCollapse: (state) => state.MenuStore.isCollapse,
      menuList: (state) => state.MenuStore.menuList,
      
    }),
    
  },
  created() {
    
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.el-menu {
  border-right: none !important;
}
</style>